import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "introduction",
      label: "Introduction to Wedding Planning in Bangalore",
    },
    {
      id: "importance-of-hiring-a-wedding-planner",
      label: "Importance of Hiring a Wedding Planner",
    },
    {
      id: "top-wedding-venues",
      label: "Top Wedding Venues in Bangalore",
    },
    {
      id: "creative-wedding-themes",
      label: "Creative Wedding Themes",
    },
    {
      id: "destination-weddings",
      label: "Destination Weddings in Bangalore",
    },
    {
      id: "catering-services",
      label: "Catering Services for Weddings",
    },
    {
      id: "wedding-photography-videography",
      label: "Wedding Photography and Videography",
    },
    {
      id: "bridal-wear-groom-outfits",
      label: "Bridal Wear and Groom Outfits",
    },
    {
      id: "guest-accommodations",
      label: "Managing Guest Accommodations",
    },
    {
      id: "entertainment-performances",
      label: "Entertainment and Performances",
    },
    {
      id: "budgeting-financial-planning",
      label: "Budgeting and Financial Planning",
    },
    {
      id: "eco-friendly-wedding-ideas",
      label: "Eco-friendly Wedding Ideas",
    },
    {
      id: "legal-aspects",
      label: "Legal Aspects of Weddings in Bangalore",
    },
    {
      id: "cultural-religious-ceremonies",
      label: "Managing Cultural and Religious Ceremonies",
    },
    {
      id: "faqs",
      label: "FAQs",
    },
    {
      id: "conclusion",
      label: "Conclusion",
    },
  ];

  useEffect(() => {
    // console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Luxurious Venues",
      body: "For a grand celebration, top wedding planners in Bangalore recommend luxurious venues like Taj West End, ITC Gardenia, and The Leela Palace. These locations provide stunning décor, exceptional service, and opulent settings for your big day.",
    },
    {
      header: "Budget-Friendly Venues",
      body: "If you’re looking to organize a wedding on budget, Bangalore offers several cost-effective yet charming venues. Options like Temple Tree Leisure, Ganjam Mantapa, and The Peacock Grove are perfect for intimate yet beautiful celebrations. Low budget wedding planners in Bangalore can help you find hidden gems that are both affordable and picturesque.",
    },
  ];

  const list2 = [
    {
      header: "Traditional Themes",
      body: "From Kannada and Tamil customs to vibrant North Indian traditions, marriage planners in Bangalore specialize in creating authentic, culturally rich wedding experiences.",
    },
    {
      header: "Contemporary Themes",
      body: "Couples looking for a modern twist can opt for minimalist décor, fusion food, and unique venues like art galleries or rooftops. Many best wedding planners in Bangalore curate these elegant, offbeat experiences with perfection.",
    },
  ];

  const list3 = [
    {
      header: "Traditional Cuisine",
      body: "A South Indian spread featuring dosas, biryanis, and authentic sweets like Mysore Pak.",
    },
    {
      header: "Multi-cuisine Menus",
      body: "For diverse palates, planners arrange a variety of Indian and international dishes.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Bangalore Wedding Planners: Different categories and importance
      </h1>

      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/jV3iKj4ilNs?si=zVSRI-ebs8Dy9BnJ"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        Weddings are one of the most memorable occasions in life, and planning
        them can be both thrilling and stressful. In Bangalore, a city known for
        its vibrant culture and dynamic lifestyle, wedding planners are crucial
        in turning dreams into reality. Whether it's the grandeur of the venue,
        the elegance of decor, or the precision in timelines, Bangalore has some
        of the most professional and creative wedding planners. If you're
        looking to organize a wedding here, these 10 essential topics can help
        you find the right wedding planner and guide you through the process.
      </p>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction to Wedding Planning in Bangalore
        </h1>
        <p>
          Bangalore is a bustling metropolis that offers a perfect blend of
          traditional and modern cultures, making it an ideal city for a
          wedding. The city boasts a variety of venues, talented{" "}
          <span className="font-semibold">
            wedding event planners in Bangalore
          </span>
          , and creative professionals who turn wedding dreams into reality.
          Whether you're looking for extravagant celebrations or organizing a
          wedding on budget, Bangalore has it all.
        </p>
        <p>
          The role of{" "}
          <span className="font-semibold">wedding organizers in Bangalore</span>{" "}
          is essential as they handle everything from logistics to décor while
          ensuring the couple's vision is brought to life. From{" "}
          <span className="font-semibold">budget wedding planners</span> to
          luxury specialists, you can find the right professionals to match your
          requirements.
        </p>
      </section>

      <section
        id="importance-of-hiring-a-wedding-planner"
        className="space-y-3"
      >
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Importance of Hiring a Wedding Planner
        </h1>
        <p>
          Planning a wedding can be overwhelming, especially with so many
          details to consider. A professional{" "}
          <span className="font-semibold">
            wedding planning companies in Bangalore
          </span>{" "}
          help ensure every aspect of the wedding is handled seamlessly. Whether
          it’s a traditional ceremony or a modern celebration, the expertise of{" "}
          <span className="font-semibold">wedding organizers in Bangalore</span>{" "}
          ensures everything runs perfectly.
        </p>
        <p>
          Key benefits of hiring{" "}
          <span className="font-semibold">Bangalore wedding planners</span>{" "}
          include:
        </p>
        <ul className="list-disc px-5 ml-5">
          <li>Stress-free execution of the event.</li>
          <li>
            Budget management with a clear{" "}
            <span className="font-semibold">marriage budget plan.</span>
          </li>
          <li>Access to trusted vendors and services at the best prices.</li>
          <li>
            Creative décor and customized solutions for your wedding style.
          </li>
        </ul>
      </section>

      <section id="top-wedding-venues" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Top Wedding Venues in Bangalore
        </h1>

        <DynamicList newLine listType="" data={list1} />
      </section>

      <section id="creative-wedding-themes" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Creative Wedding Themes
        </h1>

        <p>
          Bangalore’s{" "}
          <span className="font-semibold">
            wedding event management companies
          </span>{" "}
          are known for their innovative and creative wedding themes:
        </p>
        <DynamicList newLine listType="" data={list2} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/how-to-plan-a-destination-wedding-on-a-small-budget.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="destination-weddings" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Destination Weddings in Bangalore
        </h1>
        <p>
          While Bangalore is a thriving urban hub, it also has scenic
          surroundings ideal for destination weddings. Nearby locations like
          Nandi Hills, Coorg, and Mysore are popular choices for couples seeking
          serene backdrops. With the expertise of{" "}
          <span className="font-semibold">
            wedding event management in Bangalore
          </span>
          , you can have a destination wedding without traveling too far.
        </p>
      </section>

      <section id="catering-services" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Catering Services for Weddings
        </h1>

        <p>
          Food is a critical aspect of any wedding, and{" "}
          <span className="font-semibold">wedding planners in Bangalore</span>{" "}
          with price options ensure your guests enjoy a delectable culinary
          experience.
        </p>
        <DynamicList listType="list-disc" data={list3} />
      </section>

      <section id="wedding-photography-videography" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Wedding Photography and Videography
        </h1>
        <p>
          Capturing the special moments of your big day is vital.{" "}
          <span className="font-semibold">Bangalore wedding planners</span>{" "}
          collaborate with top photographers to provide stunning visuals—from
          candid shots to cinematic videos.
        </p>
      </section>

      <section id="bridal-wear-groom-outfits" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Bridal Wear and Groom Outfits
        </h1>

        <p>
          Bangalore is home to top designers and boutiques offering bespoke
          bridal wear and sherwanis.{" "}
          <span className="font-semibold">Wedding planners</span> can arrange
          appointments with the city’s best fashion experts to ensure you look
          your absolute best.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/low-budget-wedding-services-in-bangalore.webp"
        alt="budget-decor-ideas"
      />

      <section id="guest-accommodations" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Managing Guest Accommodations
        </h1>
        <p>
          Professional{" "}
          <span className="font-semibold">
            marriage event organisers in Bangalore
          </span>{" "}
          assist in organizing accommodations for out-of-town guests. From
          luxury hotels to affordable serviced apartments, options are tailored
          to suit your requirements.
        </p>
      </section>

      <section id="entertainment-performances" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Entertainment and Performances
        </h1>
        <p>
          Entertainment plays a big role in weddings, and{" "}
          <span className="font-semibold">wedding organizers in Bangalore</span>{" "}
          are skilled in arranging:
        </p>
        <ul className="list-disc px-5 ml-5">
          <li> Live bands, DJs, and Sufi music performances.</li>
          <li> Traditional acts like Bharatanatyam and Kathak dance shows.</li>
        </ul>
      </section>

      <section id="budgeting-financial-planning" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budgeting and Financial Planning
        </h1>
        <p>
          Managing finances is crucial to avoid overspending. Experienced{" "}
          <span className="font-semibold">budget wedding planners</span> provide
          a detailed{" "}
          <span className="font-semibold">wedding budget organizer</span> to
          help couples track expenses. They ensure the{" "}
          <span className="font-semibold">cost of wedding planner</span>
          is justified by optimizing every service without exceeding the{" "}
          <span className="font-semibold">budget of wedding planner</span>.
        </p>
        <p>
          Whether you want a luxury wedding or a{" "}
          <span className="font-semibold">
            low budget wedding planners in Bangalore
          </span>
          , there’s a perfect option for you.
        </p>
      </section>

      <section id="eco-friendly-wedding-ideas" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Eco-friendly Wedding Ideas
        </h1>
        <p>
          Bangalore’s growing focus on sustainability has made eco-friendly
          weddings a popular choice.{" "}
          <span className="font-semibold">
            Marriage event management in Bangalore
          </span>{" "}
          now offers options such as:
        </p>
        <ul className="list-disc px-5 ml-5">
          <li>Biodegradable décor.</li>
          <li>Zero-waste catering.</li>
          <li>Locally sourced flowers and organic food.</li>
        </ul>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/black-and-white-photos.webp"
        alt="success-stories"
      />

      <section id="legal-aspects" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Legal Aspects of Weddings in Bangalore
        </h1>
        <p>
          Understanding marriage laws and documentation is essential.{" "}
          <span className="font-semibold">
            Wedding event planners in Bangalore
          </span>{" "}
          assist with all legal formalities, including marriage registration and
          permits.
        </p>
      </section>

      <section id="cultural-religious-ceremonies" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Managing Cultural and Religious Ceremonies
        </h1>
        <p>
          Bangalore’s multicultural atmosphere makes it an ideal city for
          weddings of all faiths.{" "}
          <span className="font-semibold">Marriage planners in Bangalore</span>{" "}
          ensure that every cultural and religious custom, from rituals to
          prayers, is honored seamlessly.
        </p>
      </section>

      <section id="faqs" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Frequently Asked Questions (FAQ)
        </h1>

        <ul className={`list-decimal ml-5 px-5 py-2 space-y-1`}>
          <li>
            <p className="font-semibold">
              How much does a wedding planner cost in Bangalore?
            </p>
            <p>
              The{" "}
              <span className="font-semibold">
                cost of wedding planner in Bangalore
              </span>{" "}
              ranges from INR 1 lakh to 5 lakhs or more, depending on the
              services provided.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              How can I plan a wedding on a budget?
            </p>
            <p>
              Hiring{" "}
              <span className="font-semibold">
                low budget wedding planners in Bangalore
              </span>{" "}
              helps you organize a beautiful wedding without exceeding your
              budget.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              What are some top wedding venues in Bangalore?
            </p>
            <p>
              Popular venues include Taj West End, ITC Gardenia, and Temple Tree
              Leisure.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Do wedding planners handle guest accommodations?
            </p>
            <p>
              Yes, many{" "}
              <span className="font-semibold">
                wedding planning companies in Bangalore
              </span>{" "}
              take care of accommodation and logistics for guests.
            </p>
          </li>
        </ul>
      </section>

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          Hiring professional{" "}
          <span className="font-semibold">Bangalore wedding planners</span>{" "}
          ensures that your wedding is a seamless, stress-free experience.
          Whether you need{" "}
          <span className="font-semibold">budget wedding planners</span> or a
          team for luxury celebrations, the city offers a range of options to
          meet your needs. From organizing the perfect{" "}
          <span className="font-semibold">marriage budget plan</span> to
          executing your dream wedding, the expertise of{" "}
          <span className="font-semibold">
            wedding event planners in Bangalore
          </span>{" "}
          turns your vision into reality.
        </p>
        <p>
          With their support, you can create lifelong memories while enjoying
          every moment of your special day.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
