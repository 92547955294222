import React from "react";
import { Card } from "flowbite-react";
import { blogs } from "../../assets/data/blogs";
import { customCard } from "../../assets/styles/card";
import FollowUs from "./FollowUs";

const AllBlogs = () => {
  return (
    <section id="blogs" className="pb-20 px-4 container flex-grow">
      <div className="mt-32 flex flex-col lg:flex-row">
        <div className="lg:w-2/3 xl:w-3/4 lg:pr-5">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 mb-10">
            {blogs.reverse().map((blog) => (
              <div
                key={blog.id}
                className="flex cursor-pointer hover:opacity-80 w-full"
              >
                <a href={`/blogs/${blog.id}`}>
                  <Card
                    data-aos="zoom-in"
                    data-aos-delay="200"
                    imgAlt={blog.title}
                    imgSrc={blog.image}
                    className="h-full"
                    theme={customCard}
                  >
                    <p className="text-xl text-dark font-semibold dark:text-white">
                      {blog.title}
                    </p>
                    <p className="text-purple dark:text-gray-400">
                      {blog.text.length > 140
                        ? blog.text.slice(0, 140) + "..."
                        : blog.text}
                    </p>
                  </Card>
                </a>
              </div>
            ))}
          </div>
        </div>
        <FollowUs />
      </div>
    </section>
  );
};

export default AllBlogs;
