import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";
import DynamicList from "../../ui/DynamicList";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "zzeeh-weddings",
      label:
        "Zzeeh Weddings: The Best Wedding Decorators for Special Occasions in Bangalore",
    },
    {
      id: "unforgettable-stage-decoration",
      label: "Unforgettable Wedding Stage Decoration",
    },
    {
      id: "haldi-marriage-decor",
      label: "Stunning Haldi and Marriage Decorations",
    },
    {
      id: "wedding-reception",
      label: "Wedding Reception: A Celebration to Remember",
    },
    {
      id: "garland-to-remember",
      label: "A Garland to Remember",
    },
    {
      id: "why-choose-zzeeh",
      label: "Why Choose Zzeeh Weddings for Your Wedding Décor?",
    },
    {
      id: "other-occasions-decor",
      label: "Decorating Other Special Occasions",
    },
    {
      id: "get-started-zzeeh",
      label: "Get Started with Zzeeh Weddings",
    },
  ];

  const list1 = [
    {
      header: "Top Wedding Decorators in Bangalore",
      body: "As the best wedding decorators, we offer unmatched expertise in creating bespoke décor for weddings and special occasions. Whether it's a grand wedding stage or a more intimate event, we handle every detail with precision.",
    },
    {
      header: "Customized Decorations for Every Ceremony",
      body: "From the best decoration for haldi to the best wedding reception décor, we provide customized design and decoration that matches your unique style and preferences.",
    },
    {
      header: "Expert Team of Wedding Decorators",
      body: "Our team of experienced wedding professionals works closely with you to understand your vision and transform it into reality. We ensure that every decoration element, from flowers to lighting, is carefully selected for your special day.",
    },
    {
      header: "Affordable yet Stunning Designs",
      body: "At Zzeeh Weddings, we understand the importance of staying within budget while still delivering beautiful and elegant décor. We offer a range of affordable decoration options that don’t compromise on quality.",
    },
    {
      header: "Attention to Detail",
      body: "From the wedding stage decoration to the birthday wishing well, we ensure every element is thoughtfully designed to add beauty and elegance to your special occasion. Our attention to detail makes all the difference, ensuring that your wedding looks amazing in every photograph.",
    },
  ];

  useEffect(() => {
    // console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Wedding Decorators in Bangalore: Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/jV3iKj4ilNs?si=1icG-5O3Dv6lUDX7"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        <i className="text-xl font-made-mirage font-medium">
          Transforming Dreams into Beautiful Wedding Venues
        </i>
      </p>
      <p className="!mt-5">
        Weddings are a time of celebration, a once-in-a-lifetime event filled
        with love, traditions, and beauty. For many, the decor is the heart of
        the wedding, as it sets the mood, reflects personal tastes, and creates
        lasting memories. In a city like Bangalore, where tradition meets
        modernity, one name stands out among wedding decorators – Zzeeh
        Weddings.
      </p>
      <p className="!mt-3">
        <a
          href="/zzeeh_weddings"
          className="text-blue-500 font-semibold hover:underline"
        >
          Zzeeh Weddings
        </a>{" "}
        is a trusted and experienced wedding decorator in Bangalore, known for
        curating stunning and personalized wedding setups that cater to both
        traditional and contemporary styles. If you're looking to make your
        wedding a grand event, this is your go-to guide to understanding why
        Zzeeh Weddings could be the perfect choice for your big day.
      </p>

      <TableofContent tocData={tocData} />

      <section id="zzeeh-weddings" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh Weddings: The Best Wedding Decorators for Special Occasions in
          Bangalore
        </h1>
        <p>
          When it comes to special occasions like weddings, the importance of
          beautiful decorations cannot be overstated. At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we take immense pride in being recognized as the{" "}
          <span className="font-semibold">best wedding decorators</span> in
          Bangalore, offering stunning décor solutions that make your big day
          even more memorable. Whether you're looking for the{" "}
          <span className="font-semibold">best wedding decorators near me</span>{" "}
          or seeking the perfect{" "}
          <span className="font-semibold">
            decoration for haldi, reception, or wedding stage
          </span>
          , Zzeeh Weddings is your go-to choice for flawless, elegant, and
          budget-friendly wedding décor.
        </p>
      </section>

      <section id="unforgettable-stage-decoration" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Unforgettable Wedding Stage Decoration
        </h1>
        <p>
          The{" "}
          <span className="font-semibold">
            beautiful wedding stage decoration
          </span>{" "}
          is the heart of any wedding. It is where the couple exchanges vows and
          where many memorable moments unfold. At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we specialize in creating the{" "}
          <span className="font-semibold">
            best decoration for wedding stage
          </span>{" "}
          that perfectly aligns with your theme and personality. Whether you
          envision a modern, luxurious stage or a traditional, romantic setup,
          our expert decorators bring your vision to life with intricate details
          and creativity.
        </p>
        <p>
          Our{" "}
          <span className="font-semibold">best wedding stage decoration</span>{" "}
          includes stunning floral arrangements, beautiful drapery, elegant
          lighting, and unique accents that ensure your stage becomes the
          highlight of the celebration. We believe that the{" "}
          <span className="font-semibold">best decoration in wedding</span> lies
          in the harmony between your personal style and the stunning décor
          elements we bring to the table.
        </p>
      </section>

      <section id="haldi-marriage-decor" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Stunning Haldi and Marriage Decorations
        </h1>
        <p>
          Looking for the{" "}
          <span className="font-semibold">best decoration for haldi</span> or
          the perfect theme for your marriage celebration?{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          provides distinctive décor for every occasion leading up to your
          wedding. The{" "}
          <span className="font-semibold">best haldi decoration</span> includes
          vibrant colors, traditional floral arrangements, and joyful elements
          that create a lively atmosphere for this pre-wedding celebration.
        </p>
        <p>
          When it comes to your{" "}
          <span className="font-semibold">marriage decoration</span>, we focus
          on creating a festive and intimate setting with just the right balance
          of elegance and charm. From the aisle to the mandap, we make sure
          every corner is perfectly decorated, ensuring your wedding ceremony is
          filled with beauty and grace.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/indian-wedding-haldi-ceremony.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="wedding-reception" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Wedding Reception: A Celebration to Remember
        </h1>
        <p>
          Your <span className="font-semibold">wedding reception</span> is an
          extension of your celebration, and the décor sets the tone for the
          evening. At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we offer the{" "}
          <span className="font-semibold">
            best decoration for wedding reception
          </span>{" "}
          that is both chic and inviting. We pay attention to every detail, from
          table centerpieces to the entrance décor, to ensure your reception is
          the perfect blend of sophistication and fun.
        </p>
        <p>
          The{" "}
          <span className="font-semibold">best decoration for reception</span>{" "}
          often includes mood lighting, elegant floral arrangements, and a
          creative design that enhances the overall ambiance. Whether you're
          hosting a small gathering or a grand celebration, we tailor the décor
          to fit your style and needs, making sure the evening is unforgettable
          for you and your guests.
        </p>
      </section>

      <section id="garland-to-remember" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          A Garland to Remember
        </h1>
        <p>
          One of the most traditional elements in a wedding is the{" "}
          <span className="font-semibold">best garland for wedding</span>. At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we offer intricately designed floral garlands that beautifully
          complement your wedding attire and the overall décor. From vibrant
          marigold garlands to exquisite rose garlands, we create beautiful,
          personalized pieces that are a perfect blend of tradition and beauty.
        </p>
      </section>

      <section id="why-choose-zzeeh" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Choose Zzeeh Weddings for Your Wedding Décor?
        </h3>
        <DynamicList listType="list-decimal" data={list1} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/best-indian-wedding-planner.webp"
        alt="budget-decor-ideas"
      />

      <section id="other-occasions-decor" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Decorating Other Special Occasions
        </h3>
        <p>
          While we specialize in weddings,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          also offers décor services for other{" "}
          <span className="font-semibold">special occasions</span>. Whether
          you’re planning a birthday party, anniversary, or any milestone event,
          we provide the same high-quality, customized décor that will make your
          event unforgettable.
        </p>
        <p>
          We also cater to{" "}
          <span className="font-semibold">birthday wishing</span> well décor,
          where we create a charming, visually appealing wishing well as a focal
          point for guests to leave messages for the celebrant. It’s an elegant
          and unique touch that makes any celebration more memorable.
        </p>
      </section>

      <section id="get-started-zzeeh" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Get Started with Zzeeh Weddings
        </h1>
        <p>
          If you’re looking for the{" "}
          <span className="font-semibold">best wedding decorators</span> in
          Bangalore who can bring your vision to life with beauty, creativity,
          and professionalism, look no further than{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          . Our expert team ensures that every part of your event is decorated
          with care and style, from the{" "}
          <span className="font-semibold">wedding stage decoration</span> to the
          smallest details like garlands and the{" "}
          <span className="font-semibold">birthday wishing well</span>.
        </p>
        <p>
          Contact us today to begin planning the décor for your{" "}
          <span className="font-semibold">special occasion</span>, and let Zzeeh
          Weddings make your wedding, reception, or any event an unforgettable
          celebration.
        </p>
      </section>

      {/* <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/impressive-backdrop-decoration.webp"
        alt="success-stories"
      /> */}

      <GetInTouch />
    </div>
  );
};

export default Blog;
