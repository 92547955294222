import React, { useEffect, useState } from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "introduction",
      label: "Introduction",
    },
    {
      id: "top-planner",
      label: "What Makes Zzeeh Weddings One of the Top Planners in Bangalore?",
    },
    {
      id: "services",
      label: "Core Services Offered by Zzeeh Weddings",
    },
    {
      id: "themes-designs",
      label: "Innovative Wedding Themes and Designs by Zzeeh Weddings",
    },
    {
      id: "budget-grand",
      label:
        "Zzeeh Weddings: Perfect for Budget-Friendly and Grand Celebrations",
    },
    {
      id: "venues",
      label: "Top Wedding Venues in Bangalore Recommended by Zzeeh",
    },
    {
      id: "success-stories",
      label: "Success Stories: Real Weddings Planned by Zzeeh",
    },
    {
      id: "destination",
      label: "Why Zzeeh Weddings is Ideal for Destination Weddings",
    },
    {
      id: "conclusion",
      label: "Conclusion",
    },
  ];

  useEffect(() => {
    // console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Professionalism & Experience",
      body: "Zzeeh Weddings boasts years of experience in the wedding planning industry, making them one of the top event organizers in the city. Their team handles everything from intimate ceremonies to grand celebrations with precision and professionalism.",
    },
    {
      header: "Personalized Wedding Planning",
      body: "As one of the top wedding planners in Bangalore, Zzeeh is committed to creating unique weddings that reflect the couple’s personalities and preferences, whether it’s a traditional, contemporary, or themed celebration.",
    },
    {
      header: "Diverse Range of Services",
      body: "Zzeeh Weddings offers a full suite of services, making them a one-stop solution for couples looking for luxury wedding planners in Bangalore or those planning a budget-friendly event.",
    },
  ];

  const list2 = [
    {
      header: "Venue Selection & Decor",
      body: "Zzeeh Weddings helps couples find the perfect venue from their connections with the best wedding companies in Bangalore. Their decor team ensures each wedding is visually stunning.",
    },
    {
      header: "Vendor Management",
      body: "Managing caterers, photographers, and other vendors is seamless with Zzeeh’s extensive network.",
    },
    {
      header: "Budget Management",
      body: "From grand to budget weddings, Zzeeh Weddings ensures every penny is spent wisely.",
    },
    {
      header: "Wedding Coordination",
      body: "Their day-of coordination ensures a flawless event from start to finish.",
    },
  ];

  const list3 = [
    {
      header: "Traditional Indian Weddings",
      body: "Honoring cultural customs while adding modern elements.",
    },
    {
      header: "Modern Minimalist Weddings",
      body: "Sophisticated decor with an elegant, simple style.",
    },
    {
      header: "Themed & Destination Weddings",
      body: "From bohemian setups to fairytale-inspired decor, Zzeeh Weddings transforms venues to fit unique themes.",
    },
  ];

  const list4 = [
    {
      header: "Handling Budget Weddings",
      body: "Zzeeh Weddings focuses on creating breathtaking weddings even on a tight budget, leveraging their expertise and vendor relationships.",
    },
    {
      header: "Planning Extravagant Weddings",
      body: "For couples seeking a grand celebration, Zzeeh specializes in luxurious setups, earning them recognition among luxury wedding planners in Bangalore.",
    },
  ];

  const list5 = [
    {
      header: "Outdoor Garden Venues",
      body: "Perfect for nature-inspired weddings.",
    },
    {
      header: "Luxury Hotels",
      body: "Ideal for grand, full-service celebrations.",
    },
    {
      header: "Intimate Spaces",
      body: "Smaller, cozy venues for close-knit gatherings.",
    },
  ];

  const list6 = [
    {
      header: "Lavish Palace Wedding",
      body: "A royal affair with intricate decor and traditional ceremonies.",
    },
    {
      header: "Intimate Garden Wedding",
      body: "Personalized vows and nature-inspired themes.",
    },
    {
      header: "Destination Wedding in Goa",
      body: "A sunset beach ceremony with local delicacies and live music.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Top Wedding Planners in Bangalore: Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/uYj5dgb3_ok?si=TY7yUjbKmegXRvkt"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        Planning a wedding is a monumental task that requires creativity,
        precision, and the ability to handle multiple aspects of the event
        seamlessly. In a bustling city like Bangalore, with its vibrant mix of
        traditions and modern trends, hiring a wedding planner can make all the
        difference. Among the best in the industry is{" "}
        <a
          href="/zzeeh_weddings"
          className="text-blue-500 font-semibold hover:underline"
        >
          Zzeeh Weddings
        </a>
        , a highly reputed name in the{" "}
        <span className="font-semibold">
          list of wedding planners in Bangalore
        </span>
        , known for turning dreams into reality, no matter the budget or style
        of the event.
      </p>
      <p className="!mt-3">
        In this blog, we will explore why{" "}
        <a
          href="/zzeeh_weddings"
          className="text-blue-500 font-semibold hover:underline"
        >
          Zzeeh Weddings
        </a>{" "}
        stands out as one of the{" "}
        <span className="font-semibold">top wedding planners in Bangalore</span>
        , offering a range of services tailored to create unforgettable
        weddings.
      </p>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          Organizing a wedding involves numerous elements, from selecting the
          perfect venue to curating a memorable theme, ensuring that every
          detail is executed with precision. While this can be overwhelming for
          couples,{" "}
          <span className="font-semibold">wedding planning companies</span> in
          Bangalore like{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          take on the responsibility of turning dreams into reality, ensuring a
          stress-free experience for the bride and groom.
        </p>
        <p>
          With a focus on personalization, efficiency, and creativity,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          has become one of the{" "}
          <span className="font-semibold">
            most successful wedding planners
          </span>{" "}
          in Bangalore, catering to a wide array of wedding styles and budgets.
        </p>
      </section>

      <section id="top-planner" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          What Makes Zzeeh Weddings One of the Top Planners in Bangalore?
        </h1>
        <DynamicList newLine listType="list-decimal" data={list1} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/the-wedding-planner-budget.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="services" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Core Services Offered by Zzeeh Weddings
        </h1>
        <DynamicList newLine listType="list-decimal" data={list2} />
      </section>

      <section id="themes-designs" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Innovative Wedding Themes and Designs by Zzeeh Weddings
        </h1>
        <DynamicList newLine listType="list-decimal" data={list3} />
        <p>
          As one of the{" "}
          <span className="font-semibold">most successful event planners</span>,
          Zzeeh takes pride in offering innovative solutions for every couple.
        </p>
      </section>

      <section id="budget-grand" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Zzeeh Weddings: Perfect for Budget-Friendly and Grand Celebrations
        </h1>
        <DynamicList newLine listType="list-decimal" data={list4} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/simple-marriages-decoration.webp"
        alt="budget-decor-ideas"
      />

      <section id="venues" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Top Wedding Venues in Bangalore Recommended by Zzeeh
        </h3>
        <p>
          {" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          works with some of the most popular venues in the city:
        </p>
        <DynamicList listType="list-disc" data={list5} />
      </section>

      <section id="success-stories" className="space-y-3">
        <h3 className="text-xl lg:text-2xl font-medium font-gt-super">
          Success Stories: Real Weddings Planned by Zzeeh
        </h3>
        <DynamicList newLine listType="list-decimal" data={list6} />
        <p>
          These examples solidify Zzeeh’s reputation as one of the{" "}
          <span className="font-semibold">
            most successful wedding planners
          </span>{" "}
          in Bangalore.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-reception-decor-zzeehwedding.webp"
        alt="success-stories"
      />

      <section id="destination" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Zzeeh Weddings is Ideal for Destination Weddings
        </h1>
        <p>
          From venue scouting to travel logistics,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          handles everything, making them a top choice for destination weddings.
          Their expertise ensures couples experience stress-free planning,
          whether near Bangalore or at exotic locations.
        </p>
      </section>

      {/* <section id="client-testimonials" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Client Testimonials and Reviews
        </h1>
        <p>
          Zzeeh Weddings is praised by clients for their dedication, creativity,
          and attention to detail. Many couples commend their ability to bring
          their dream weddings to life, offering stress-free planning and
          flawless execution.
        </p>
      </section> */}

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          With their creativity, professionalism, and attention to detail, Zzeeh
          Weddings is undoubtedly one of the{" "}
          <span className="font-semibold">
            top wedding planners in Bangalore
          </span>
          . Whether you’re looking for{" "}
          <span className="font-semibold">marriage planners in Bangalore</span>{" "}
          for an intimate gathering or a lavish celebration, Zzeeh ensures every
          element is flawless.
        </p>
        <p>
          For couples searching for{" "}
          <span className="font-semibold">event management top companies</span>,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          is the go-to option for a perfect wedding day. Start planning your
          dream wedding with Zzeeh today!
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
