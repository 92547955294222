import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";
import DynamicList from "../../ui/DynamicList";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    {
      id: "intro",
      label:
        "Creating Your Dream Wedding on a Budget with Zzeeh Weddings, Bangalore's Top Wedding Event Planner",
    },
    {
      id: "why-choose",
      label: "Why Choose Zzeeh Weddings for Your Budget-Friendly Wedding?",
    },
    {
      id: "affordable-planning",
      label: "Affordable Wedding Planning Without Sacrificing Quality",
    },
    {
      id: "budget-services",
      label: "Budget-Friendly Services Tailored Just for You",
    },
    { id: "stress-free", label: "Stress-Free Planning for Your Dream Wedding" },
    {
      id: "planning-wedding",
      label: "Planning Your Wedding With Zzeeh Weddings",
    },
    { id: "conclusion", label: "Conclusion" },
  ];

  const list1 = [
    {
      header: "Wedding Budget Planner Excel",
      body: "A tool that helps you track your wedding expenses and stick to your planned budget.",
    },
    {
      header: "Wedding Event Planner Cost",
      body: "We provide transparent pricing and work within your financial limits.",
    },
    {
      header: "Wedding Planning on a Tight Budget",
      body: "With our years of experience, we can make a limited budget go further, offering solutions that align with your vision.",
    },
    {
      header: "Best Wedding Planners in Bangalore",
      body: "We combine creativity, experience, and budgeting skills to deliver your dream wedding without exceeding your financial plan.",
    },
  ];

  useEffect(() => {
    // console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Budget Muslim Weddings in Bangalore by Zzeeh Weddings
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/-c1Z7IGOdro?si=LqCmvDfmONsQVnks"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p>
        Planning a wedding is an exciting journey, but it can often come with a
        hefty price tag. However, in Bangalore,{" "}
        <a
          href="/zzeeh_weddings"
          className="text-blue-500 font-semibold hover:underline"
        >
          Zzeeh Weddings
        </a>{" "}
        has made it possible for couples to celebrate their special day on a
        budget without compromising on elegance and tradition. In this article,
        we’ll dive into how to plan a budget-friendly Muslim wedding in
        Bangalore with the help of{" "}
        <a
          href="/zzeeh_weddings"
          className="text-blue-500 font-semibold hover:underline"
        >
          Zzeeh Weddings
        </a>
        . From venue selection to décor, catering, and more, let’s explore ways
        to create a memorable wedding that aligns with your budget.
      </p>

      <TableofContent tocData={tocData} />

      <section id="intro" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Creating Your Dream Wedding on a Budget with Zzeeh Weddings,
          Bangalore's Top Wedding Event Planner
        </h1>
        <p>
          Your wedding day is one of the most special moments of your life, and
          while you want it to be nothing short of perfect, the costs can often
          feel overwhelming. At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we specialize in helping couples plan their dream weddings without
          breaking the bank. As one of the{" "}
          <span className="font-semibold">
            best wedding planners in Bangalore
          </span>
          , we provide tailored services to create memorable experiences while
          respecting your budget. Whether you're looking for an{" "}
          <span className="font-semibold">affordable wedding planner</span> or
          need guidance on how to plan your{" "}
          <span className="font-semibold">wedding on a budget</span>,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          is here to make your big day extraordinary.
        </p>
      </section>

      <section id="why-choose" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Choose Zzeeh Weddings for Your Budget-Friendly Wedding?
        </h1>
        <p>
          Planning a wedding can be stressful, especially when you’re trying to
          stay within a set financial limit. That’s where we come in. At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we are{" "}
          <span className="font-semibold">
            top wedding planners in Bangalore
          </span>{" "}
          with a focus on helping couples achieve their dream wedding without
          compromising on quality. Our expertise in{" "}
          <span className="font-semibold">
            wedding event planning on a tight budget
          </span>{" "}
          ensures you get the most value for every rupee spent.
        </p>
        <p>
          As an experienced{" "}
          <span className="font-semibold">wedding event planner</span>, we
          understand that your wedding day is about more than just the budget –
          it's about creating memories that last a lifetime. We work closely
          with you to design a wedding that reflects your vision, preferences,
          and financial constraints, ensuring everything fits within your{" "}
          <span className="font-semibold">wedding budget planner excel</span>{" "}
          and <span className="font-semibold">wedding budget creator</span>{" "}
          tools.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/dress-appropriate.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="affordable-planning" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Affordable Wedding Planning Without Sacrificing Quality
        </h2>
        <p>
          Many couples often fear that hiring a{" "}
          <span className="font-semibold">wedding planner near me</span> will
          cost too much, but{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          offers{" "}
          <span className="font-semibold">inexpensive wedding planner</span>{" "}
          services that don’t skimp on quality. From stunning decor to perfect
          coordination, we make sure your wedding day is flawless. Our{" "}
          <span className="font-semibold">cheap wedding budget</span> options
          allow you to enjoy a beautiful wedding without the stress of
          overspending.
        </p>
        <p>
          We also provide helpful resources such as{" "}
          <span className="font-semibold">wedding planning excel sheets</span>,
          which can guide you through every financial detail. Whether you are
          trying to keep track of vendor payments, guest lists, or venue costs,
          these sheets help you stay organized, on time, and within your
          designated budget.
        </p>
      </section>

      <section id="budget-services" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Budget-Friendly Services Tailored Just for You
        </h2>
        <p>
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we tailor our services to meet your needs. Our goal is to help you
          make the most of your budget while ensuring that your wedding day is a
          reflection of your dreams. We understand the importance of sticking to
          a budget, and that's why we take pride in being one of the most
          trusted{" "}
          <span className="font-semibold">affordable wedding planners</span> in
          Bangalore. Here's what we offer:
        </p>
        <DynamicList listType="list-disc" data={list1} />
      </section>

      <section id="stress-free" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Stress-Free Planning for Your Dream Wedding
        </h2>
        <p>
          When it comes to{" "}
          <span className="font-semibold">wedding planning</span>, staying
          organized is crucial to ensuring everything falls into place. Our{" "}
          <span className="font-semibold">wedding budget creator</span> tools
          and expert advice allow you to stay on track and stress-free. As your{" "}
          <span className="font-semibold">wedding planner near me</span>, we
          handle everything from venue selection to decor, ensuring you enjoy
          every step of the planning process.
        </p>
        <p>
          We also offer the option of customizing wedding packages based on your
          preferences, whether you’re looking for a small intimate gathering or
          a grand celebration. Our{" "}
          <span className="font-semibold">wedding event planner cost</span> is
          designed to offer flexibility, allowing you to choose the best
          services that match your budget.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/affordable-wedding-venues.webp"
        alt="budget-decor-ideas"
      />

      <section id="planning-wedding" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Planning Your Wedding With Zzeeh Weddings
        </h2>
        <p>
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we believe that a wedding doesn't have to be extravagant to be
          beautiful. By working with our{" "}
          <span className="font-semibold">inexpensive wedding planner</span>{" "}
          services, you get access to high-quality, professional planning at a
          price you can afford. We handle all the details, from{" "}
          <span className="font-semibold">stage decoration</span> to the perfect
          wedding day timeline, ensuring your event is nothing short of
          spectacular. Our personalized approach guarantees a seamless
          experience that exceeds expectations.
        </p>
        <p>
          Let us help you create the wedding of your dreams without the
          financial stress. Our team of professional{" "}
          <span className="font-semibold">
            top wedding planners in Bangalore
          </span>{" "}
          will work with you to create an experience that reflects your unique
          love story.
        </p>
      </section>

      <section id="conclusion" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h2>
        <p>
          Planning a wedding on a budget doesn’t mean sacrificing quality or
          beauty. At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we specialize in delivering high-end weddings at a price you can
          afford. Whether you’re using our{" "}
          <span className="font-semibold">wedding budget planner excel</span> or
          need guidance on{" "}
          <span className="font-semibold">
            wedding planning on a tight budget
          </span>
          , we’re here to help you create a memorable and stunning event that
          you and your guests will cherish forever.
        </p>
        <p>
          Reach out to{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , your{" "}
          <span className="font-semibold">trusted wedding event planner</span>{" "}
          in Bangalore, and let’s start planning your dream wedding today—on
          your terms and within your budget.
        </p>
      </section>

      {/* <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Go-digital-wedding-photographer.webp"
        alt="success-stories"
      /> */}

      <GetInTouch />
    </div>
  );
};

export default Blog;
