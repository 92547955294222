import React, { useEffect, useState } from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";
import DynamicList from "../../ui/DynamicList";

const Blog = () => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const tocData = [
    { id: "intro", label: "Introduction" },
    {
      id: "why-best-choice",
      label: "Why Zzeeh Weddings is the Best Choice for Your Wedding",
    },
    {
      id: "our-services",
      label: "Our Services: Tailored to Your Needs",
    },
    {
      id: "zzeeh-difference",
      label: "Experience the Zzeeh Weddings Difference",
    },
    {
      id: "trust-zzeeh",
      label: "Why Trust Zzeeh Weddings for Your Big Day?",
    },
    { id: "conclusion", label: "Conclusion" },
  ];

  useEffect(() => {
    // console.log(window.innerWidth);
    if (window.innerWidth < 1024) {
      setIsMobileScreen(true);
    }
  }, []);

  const list1 = [
    {
      header: "Best Wedding Coordinator in Bangalore",
      body: "A well-coordinated wedding is the key to a stress-free day. As the best wedding coordinator in Bangalore, we work with you every step of the way to ensure everything falls into place perfectly. From coordinating vendors to managing the event schedule, our expert planners handle all the logistics so you can focus on enjoying your special day.",
    },
    {
      header: "Top Wedding Planners for Destination Weddings",
      body: "Looking to tie the knot in an exotic location? Zzeeh Weddings is one of the best destination wedding planners, specializing in curating beautiful weddings in scenic locales. Whether it's a beachside ceremony or a mountain retreat, we manage all the details, ensuring that your destination wedding is a seamless experience for both you and your guests.",
    },
    {
      header: "Good Wedding Planners with Personal Touch",
      body: "As good wedding planners, we believe every wedding should reflect the personality of the couple. We go beyond traditional wedding planning and take the time to understand your vision. This personalized approach ensures your wedding is unique, meaningful, and truly yours.",
    },
  ];

  const list2 = [
    {
      header: "Dream Event Planning",
      body: "We create a memorable experience from start to finish.",
    },
    {
      header: "Best Planner for Weddings",
      body: "We provide expert guidance to help you make informed decisions.",
    },
    {
      header: "Best Wedding Organiser",
      body: "We manage everything, from decorations to catering, ensuring everything runs smoothly.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        Dream Weddings Made Reality: A 2024 Review of Zzeeh Weddings' Planning
        Services
      </h1>
      <iframe
        width="100%"
        height={isMobileScreen ? "300" : "500"}
        src="https://www.youtube.com/embed/PzCZqn47ejs?si=ZOVXfP_zm_Efh1lv"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          When it comes to planning your dream wedding, you want nothing but the
          best. Finding the perfect wedding planner to bring your vision to life
          is essential. For couples looking for top-tier service and a
          personalized touch,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          in Bangalore is your go-to partner. As one of the{" "}
          <span className="font-semibold">
            best wedding planners in Bangalore
          </span>
          ,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          excels in creating unforgettable experiences for every couple, whether
          you're looking for a luxurious{" "}
          <span className="font-semibold">destination wedding</span> or a
          traditional celebration.
        </p>
      </section>

      <section id="why-best-choice" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Zzeeh Weddings is the Best Choice for Your Wedding
        </h1>
        <p>
          As <span className="font-semibold">top wedding planners</span> in
          Bangalore,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          takes pride in offering personalized services that cater to your
          unique needs. From{" "}
          <span className="font-semibold">dream wedding planning</span> to every
          little detail, we ensure your event is a true reflection of your love
          story. Whether you're searching for the{" "}
          <span className="font-semibold">best wedding planners near me</span>{" "}
          or seeking a planner who specializes in{" "}
          <span className="font-semibold">Indian wedding celebrations</span>,
          we’re here to make your dream day come true.
        </p>
        <p>
          With years of experience and a reputation for excellence, we have
          earned our place as one of the{" "}
          <span className="font-semibold">best wedding organizers</span> in the
          region. Our expertise in handling all aspects of wedding planning,
          from venue selection to{" "}
          <span className="font-semibold">wedding coordination</span>, allows us
          to turn your ideas into reality seamlessly.
        </p>
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/strategies-to-plan-a-surprise-party-with-the-help-of-event-planners.webp"
        alt="bangalore-budget-wedding"
      />

      <section id="our-services" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Our Services: Tailored to Your Needs
        </h2>
        <p>
          At{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , we are proud to be recognized as one of the{" "}
          <span className="font-semibold">best Indian wedding planners</span>.
          We understand the intricate customs and traditions that come with an
          Indian wedding and strive to ensure every ceremony is a blend of
          elegance and authenticity. Whether it's an intimate gathering or a
          grand celebration, our team manages everything—from{" "}
          <span className="font-semibold">
            stage decoration to guest coordination
          </span>
          , ensuring that your big day runs smoothly and beautifully.
        </p>
        <DynamicList newLine listType="list-decimal" data={list1} />
      </section>

      <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-planner-services-in-bangalore.webp"
        alt="budget-decor-ideas"
      />

      <section id="zzeeh-difference" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Experience the Zzeeh Weddings Difference
        </h2>
        <p>
          What sets{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          apart from other{" "}
          <span className="font-semibold">
            top wedding planners in Bangalore
          </span>{" "}
          is our attention to detail and commitment to excellence. Our goal is
          to bring your dream event to life while handling all aspects of the
          planning process with ease. Our services include:
        </p>
        <DynamicList listType="list-disc" data={list2} />
        <p>
          With{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>
          , every element of your wedding will be carefully crafted to
          perfection. Our team handles everything, from{" "}
          <span className="font-semibold">wedding coordination to decor</span>,
          so you can focus on what truly matters—celebrating your love.
        </p>
      </section>

      <section id="trust-zzeeh" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Trust Zzeeh Weddings for Your Big Day?
        </h2>
        <p>
          We are honored to be regarded as the{" "}
          <span className="font-semibold">best wedding planners</span> and{" "}
          <span className="font-semibold">best wedding organiser</span> in
          Bangalore. Our reputation for delivering exceptional weddings comes
          from our dedication to providing seamless, stress-free experiences for
          every couple. Whether you're planning a traditional wedding or a
          modern celebration, we tailor our services to fit your needs, ensuring
          that every detail aligns with your vision.
        </p>
        <p>
          We understand the challenges that come with planning a wedding, and we
          are here to make the process easier. As one of the{" "}
          <span className="font-semibold">best wedding planners near me</span>,{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          is committed to providing the highest level of service and creating
          weddings that exceed expectations.
        </p>
      </section>

      {/* <img
        src="https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/how-do-you-plan-a-destination-wedding.webp"
        alt="success-stories"
      /> */}

      <section id="conclusion" className="space-y-3">
        <h2 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h2>
        <p>
          When it comes to planning your{" "}
          <span className="font-semibold">dream wedding</span>, trust{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          to make your vision a reality. As one of the{" "}
          <span className="font-semibold">best wedding planners</span> in
          Bangalore, we specialize in{" "}
          <span className="font-semibold">
            destination weddings, Indian weddings
          </span>
          , and everything in between. From{" "}
          <span className="font-semibold">top wedding planners</span> to the{" "}
          <span className="font-semibold">best wedding coordinators</span>, our
          team ensures that every detail of your wedding is handled with care,
          professionalism, and love.
        </p>
        <p>
          Let{" "}
          <a
            href="/zzeeh_weddings"
            className="text-blue-500 font-semibold hover:underline"
          >
            Zzeeh Weddings
          </a>{" "}
          bring your dream event to life. Contact us today to start planning
          your unforgettable wedding!
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
