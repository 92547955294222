import uniqueDecorationIdeasandThemesforCorporateEvents from "../../components/Blogs/content/uniqueDecorationIdeasandThemesforCorporateEvents";
import aGuidetoWeddingFlowerDecorationsandArrangements from "../../components/Blogs/content/aGuidetoWeddingFlowerDecorationsandArrangements";
import bestWeddingPlannersInBangalore from "../../components/Blogs/content/bestWeddingPlannersInBangalore";
import budgetWeddingPlannerinBangalore from "../../components/Blogs/content/budgetWeddingPlannerinBangalore";
import topWeddingPlannersinBangalore from "../../components/Blogs/content/topWeddingPlannersinBangalore";
import topNotchWeddingPlannersinBangaloreAtBudgetPrice from "../../components/Blogs/content/topNotchWeddingPlannersinBangaloreAtBudgetPrice";
import bestHinduWeddingPlannersinBangalore from "../../components/Blogs/content/bestHinduWeddingPlannersinBangalore";
import weddingDecoratorsinBangalore from "../../components/Blogs/content/weddingDecoratorsinBangalore";
import destinationWeddingPlannersinBangalore from "../../components/Blogs/content/destinationWeddingPlannersinBangalore";
import muslimWeddingPlannersinBangaloreAComprehensiveGuide from "../../components/Blogs/content/muslimWeddingPlannersinBangaloreAComprehensiveGuide";
import bangaloreWeddingPlannersDifferentcategoriesandimportance from "../../components/Blogs/content/bangaloreWeddingPlannersDifferentcategoriesandimportance";
import fromVisiontoRealityZzeehWeddingsYourPremierWeddingEventPlannerinBangalore from "../../components/Blogs/content/fromVisiontoRealityZzeehWeddingsYourPremierWeddingEventPlannerinBangalore";
import budgetHinduWeddingDecorbyZzeehWeddings from "../../components/Blogs/content/budgetHinduWeddingDecorbyZzeehWeddings";
import budgetMuslimWeddingsinBangalorebyZzeehWeddings from "../../components/Blogs/content/budgetMuslimWeddingsinBangalorebyZzeehWeddings";
import dreamWeddingsMadeRealityA2024ReviewofZzeehWeddingsPlanningServices from "../../components/Blogs/content/dreamWeddingsMadeRealityA2024ReviewofZzeehWeddingsPlanningServices";
import weddingBudgetPlanninginBangaloreWhattoExpectin2025 from "../../components/Blogs/content/weddingBudgetPlanninginBangaloreWhattoExpectin2025";
import multiDayWeddingPlanninginBangaloreLogisticsandManagement from "../../components/Blogs/content/multiDayWeddingPlanninginBangaloreLogisticsandManagement";
import behindtheScenesofaZzeehWeddingHowWeMakeMagicHappen from "../../components/Blogs/content/behindtheScenesofaZzeehWeddingHowWeMakeMagicHappen";
import uniqueWeddingThemeIdeasThatWillWowYourGuests from "../../components/Blogs/content/uniqueWeddingThemeIdeasThatWillWowYourGuests";
import destinationWeddingvsLocalWeddingProsandConsforBangaloreCouples from "../../components/Blogs/content/destinationWeddingvsLocalWeddingProsandConsforBangaloreCouples";

export const blogs = [
  {
    id: "best-wedding-planners-in-bangalore-by-zzeeh-weddings",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/A+Guide+to+Wedding+Flower+Decorations+and+Arrangements/wedding-flower-decorations-arrangements.webp",
    title: "Zzeeh Weddings: Best Wedding Planners in Bangalore 2024",
    text: "There’s something magical about weddings. Two souls uniting, surrounded by love, laughter, and cherished memories.",
    blog: bestWeddingPlannersInBangalore,
  },
  {
    id: "budget-wedding-planner-in-bangalore-zzeeh-weddings",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Best-Wedding-Planners-in-Bangalore/flower-wedding-stage-decoration.webp",
    title: "Budget Wedding Planner in Bangalore: Zzeeh Weddings",
    text: "Planning a wedding is an exciting yet daunting task, especially when you're on a budget. For couples dreaming of a beautiful celebration without breaking the bank, Zzeeh Weddings in Bangalore offers professional wedding planning services that cater to all kinds of budgets.",
    blog: budgetWeddingPlannerinBangalore,
  },
  {
    id: "top-wedding-planners-in-bangalore-zzeeh-weddings",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/the-wedding-planner-budget.webp",
    title: "Top Wedding Planners in Bangalore: Zzeeh Weddings",
    text: "Planning a wedding is a monumental task that requires creativity, precision, and the ability to handle multiple aspects of the event seamlessly.",
    blog: topWeddingPlannersinBangalore,
  },
  {
    id: "top-notch-wedding-planners-in-bangalore-budget-price",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/small-wedding-reception.webp",
    title: "Top-Notch Wedding Planners in Bangalore at Budget Price",
    text: 'Bangalore, known as the "Silicon Valley of India," has become a hotspot for destination and local weddings. However, the rising costs associated with wedding planning can be overwhelming for many couples.',
    blog: topNotchWeddingPlannersinBangaloreAtBudgetPrice,
  },
  {
    id: "best-hindu-wedding-planners-in-bangalore-zzeeh-weddings",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/neha-and-santhosh-wedding-moments.webp",
    title: "Best Hindu Wedding Planners in Bangalore: Zzeeh Weddings",
    text: "Planning a wedding is one of the most joyous yet challenging tasks. In India, weddings hold a special cultural significance, especially in Hindu traditions, where intricate rituals and vibrant customs are celebrated over several days.",
    blog: bestHinduWeddingPlannersinBangalore,
  },
  {
    id: "wedding-decorators-in-bangalore-zzeeh-weddings",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/best-places-for-destination-weddings.webp",
    title: "Wedding Decorators in Bangalore: Zzeeh Weddings",
    text: "Weddings are a time of celebration, a once-in-a-lifetime event filled with love, traditions, and beauty. For many, the decor is the heart of the wedding, as it sets the mood, reflects personal tastes, and creates lasting memories.",
    blog: weddingDecoratorsinBangalore,
  },
  {
    id: "destination-wedding-planners-in-bangalore-zzeeh-weddings",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/simple-marriages-decoration.webp",
    title: "Destination Wedding Planners in Bangalore: Zzeeh Weddings",
    text: "Destination weddings are a growing trend, offering couples a chance to tie the knot in a picturesque location, away from the hustle of everyday life.",
    blog: destinationWeddingPlannersinBangalore,
  },
  {
    id: "muslim-wedding-planners-in-bangalore-comprehensive-guide",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/best-indian-wedding-planner.webp",
    title: "Muslim Wedding Planners in Bangalore: A Comprehensive Guide",
    text: "Muslim weddings, also known as Nikah, are deeply rooted in Islamic traditions and cultural heritage. They are not only sacred ceremonies but also a beautiful celebration of love and unity between families. ",
    blog: muslimWeddingPlannersinBangaloreAComprehensiveGuide,
  },
  {
    id: "bangalore-wedding-planners-different-categories-and-importance",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/black-and-white-photos.webp",
    title: "Bangalore Wedding Planners: Different categories and importance",
    text: "Bangalore is a bustling metropolis that offers a perfect blend of traditional and modern cultures, making it an ideal city for a wedding. The city boasts a variety of wedding venues, services, and talented professionals who can make any wedding dream come true.",
    blog: bangaloreWeddingPlannersDifferentcategoriesandimportance,
  },
  {
    id: "from-vision-to-reality-zzeeh-weddings-your-premier-wedding-event-planner-in-bangalore",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/hanging-wedding-decors.webp",
    title:
      "From Vision to Reality: Zzeeh Weddings – Your Premier Wedding Event Planner in Bangalore",
    text: "Weddings are one of the most special days in a couple’s life. But let’s face it, planning a wedding can be overwhelming! This is where wedding planners come in, turning your dream wedding into reality.",
    blog: fromVisiontoRealityZzeehWeddingsYourPremierWeddingEventPlannerinBangalore,
  },
  {
    id: "budget-hindu-wedding-decor-by-zzeeh-weddings",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/bollywood-style-wedding-planning.webp",
    title: "Budget Hindu Wedding Decor by Zzeeh Weddings",
    text: "Planning a beautiful Hindu wedding doesn’t have to mean breaking the bank. With Zzeeh Weddings’ expertise in elegant and budget-friendly decor, you can bring the grandeur of traditional Hindu aesthetics to life.",
    blog: budgetHinduWeddingDecorbyZzeehWeddings,
  },
  {
    id: "budget-muslim-weddings-in-bangalore-by-zzeeh-weddings",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/Go-digital-wedding-photographer.webp",
    title: "Budget Muslim Weddings in Bangalore by Zzeeh Weddings",
    text: "Planning a wedding is an exciting journey, but it can often come with a hefty price tag. However, in Bangalore, Zzeeh Weddings has made it possible for couples to celebrate their special day on a budget without compromising on elegance and tradition.",
    blog: budgetMuslimWeddingsinBangalorebyZzeehWeddings,
  },
  {
    id: "dream-weddings-made-reality-a-2024-review-of-zzeeh-weddings-planning-services",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-planner-services-in-bangalore.webp",
    title:
      "Dream Weddings Made Reality: A 2024 Review of Zzeeh Weddings' Planning Services",
    text: "Planning a wedding is a monumental task filled with countless details, from choosing the right venue to finding the perfect decor. Enter Zzeeh Weddings, a premier wedding planning service that turns dreams into reality by crafting unforgettable events tailored to each couple’s unique vision",
    blog: dreamWeddingsMadeRealityA2024ReviewofZzeehWeddingsPlanningServices,
  },
  // {
  //   id: "wedding-budget-planning-in-bangalore-what-to-expect-in-2025",
  //   image:
  //     "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-planner-services-in-bangalore.webp",
  //   title: "Wedding Budget Planning in Bangalore: What to Expect in 2025",
  //   text: "Bangalore is a melting pot of cultures, and multi-day weddings have deep roots in traditions. Celebrations like mehendi, sangeet, and the reception each hold unique significance and create an unforgettable experience for families.",
  //   blog: weddingBudgetPlanninginBangaloreWhattoExpectin2025,
  // },
  // {
  //   id: "multi-day-wedding-planning-in-bangalore-logistics-and-management",
  //   image:
  //     "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-planner-services-in-bangalore.webp",
  //   title: "Multi-Day Wedding Planning in Bangalore: Logistics and Management",
  //   text: "Bangalore is a melting pot of cultures, and multi-day weddings have deep roots in traditions. Celebrations like mehendi, sangeet, and the reception each hold unique significance and create an unforgettable experience for families.",
  //   blog: multiDayWeddingPlanninginBangaloreLogisticsandManagement,
  // },
  // {
  //   id: "behind-the-scenes-of-a-zzeeh-wedding-how-we-make-magic-happen",
  //   image:
  //     "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-planner-services-in-bangalore.webp",
  //   title: "Behind the Scenes of a Zzeeh Wedding: How We Make Magic Happen",
  //   text: "Have you ever wondered how dream weddings come to life? From fairy lights to heartfelt vows, every detail is a piece of a bigger puzzle. At Zzeeh Weddings, we don’t just plan events; we create memories.",
  //   blog: behindtheScenesofaZzeehWeddingHowWeMakeMagicHappen,
  // },
  // {
  //   id: "10-unique-wedding-theme-ideas-that-will-wow-your-guests",
  //   image:
  //     "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-planner-services-in-bangalore.webp",
  //   title: "10 Unique Wedding Theme Ideas That Will Wow Your Guests",
  //   text: "Your wedding day is one of the most special days of your life, so why not make it unforgettable with a unique theme? A creative wedding theme not only sets the tone for your celebration but also leaves a lasting impression on your guests. Ready to turn your big day into a one-of-a-kind experience? Let’s dive into some inspiring ideas!",
  //   blog: uniqueWeddingThemeIdeasThatWillWowYourGuests,
  // },
  // {
  //   id: "destination-wedding-vs-local-wedding-pros-and-cons-for-bangalore-couples",
  //   image:
  //     "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/wedding-planner-services-in-bangalore.webp",
  //   title:
  //     "Destination Wedding vs. Local Wedding: Pros and Cons for Bangalore Couples",
  //   text: "Weddings are a magical affair, but the big question remains: Should you have a grand local wedding or jet off to an exotic destination? For couples in Bangalore, the decision can be tricky. Let’s break down the pros and cons of both options so you can make the perfect choice for your big day!",
  //   blog: destinationWeddingvsLocalWeddingProsandConsforBangaloreCouples,
  // },
];

export const old_blogs = [
  {
    id: "11-unique-decoration-ideas-and-themes-for-corporate-events",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/11+Unique+Decoration+Ideas+and+Themes+for+Corporate+events/themes-for-corporate-events.webp",
    title: "11 Unique Decoration Ideas and Themes for Corporate Events",
    text: "Decorations are an integral part of any event, especially corporate events. Whether it is a sales meeting, trade show, or a celebration event, the right decorations can make all the difference in setting the tone, creating an atmosphere, and leaving a lasting impression on attendees.",
    blog: uniqueDecorationIdeasandThemesforCorporateEvents,
  },
  {
    id: "a-guide-to-wedding-flower-decorations-and-arrangements",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/A+Guide+to+Wedding+Flower+Decorations+and+Arrangements/marriage-flower-decoration.webp",
    title: "A Guide to Wedding Flower Decorations and Arrangements",
    text: "Flowers play an integral role in wedding decorations and arrangements, as they add beauty, color, and fragrance to the event. They can transform a plain and ordinary space into a stunning and romantic setting, creating a memorable experience for both the couple and their",
    blog: aGuidetoWeddingFlowerDecorationsandArrangements,
  },
  {
    id: "a-dreamy-bollywood-inspired-wedding-in-bangalore",
    image:
      "https://zzeeh.s3.ap-south-1.amazonaws.com/website/blogs/A+Dreamy+Bollywood-Inspired+Wedding+in+Bangalore/destination-wedding-packages-with-prices-66b1edd56de70.webp",
    title: "A Dreamy Bollywood-Inspired Wedding in Bangalore",
    text: "They met at work in Dubai, they fell in love, and then they decided to have a destination wedding in Bangalore that was inspired by their favorite Bollywood movie, 2 States. From the vibrant pink mandap to the traditional south Indian ceremony mixed with north Indian rituals, this wedding was a true feast for the senses.",
  },
];
