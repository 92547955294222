import React from "react";
import { Button } from "flowbite-react";

const CTAButton = ({
  name,
  handleClick,
}: {
  name: string;
  handleClick: () => void;
}) => {
  return (
    <Button
      className="relative md:font-medium bg-black hover:!bg-gray-800 focus:ring-0 group"
      onClick={handleClick}
    >
      <p className="text-xs md:text-sm">
        {name}
        <span className="ml-1 inline-block transition-transform duration-300 transform group-hover:translate-x-1">{`->`}</span>
      </p>
    </Button>
  );
};

export default CTAButton;
